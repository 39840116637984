.mainBackground{
  background: #567280;
  padding-bottom: 5%;
  clip-path: polygon(0 0, 100% 0, 100% 91%, 0% 100%);
}
.logo{
  width: 300px;
}
.input-group{
  box-shadow: 7px 9px 15px 5px #ffffff54;
}
.input-group input{
  border-left: 0px;
  padding: 15px;
  padding-left: 0px;
}
.input-group-text{
  background: white;
  color: gray;
}
.form-control:focus{
  box-shadow: none;
}
.questions h2 span{
  cursor: pointer;
}
.questions h2 span:hover{
  cursor: pointer;
  text-decoration:underline;
}
.fa-circle{
  position: relative;
  top: 9px;
  margin-right: 10px;
  font-size: 12px;
}
.form-select{
  width: 100px;
  box-shadow: 7px 9px 15px 5px #ffffff54;
  position: absolute;
  right: 20px;
  top: 20px;
}
